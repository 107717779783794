import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Link } from "@reach/router"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./transition.css"
import { Container, Row, Col } from "react-bootstrap"
import { useIntl } from "gatsby-plugin-intl"
import moment from "moment"

const Articles = props => {
  const intl = useIntl()
  const locale = intl.locale !== "id" ? `/${intl.locale}` : ""
  let data = useStaticQuery(graphql`
    query ArticleQuery {
      allArticle {
        nodes {
          title
          imageUrl
          pathUrl
          title_slug
          page_slug
          lang
          created_at
        }
      }
    }
  `)
  data = data.allArticle.nodes.filter(function (item) {
    return item.lang === intl.locale
  })
  return (
    <div className={"page"}>
      <Layout>
        <SEO title="About Us" />
        <div className="row mt-5">
          <Container>
            <div id="page-content-wrapper">
              <h1 className="mb-4 txt-primary text-center">
                <b style={{ color: "#ad1457" }}>
                  {intl.formatMessage({ id: "blog" })}
                </b>
              </h1>
              <p className="text-center">
                {intl.formatMessage({ id: "article-desc" })}
              </p>
              <br />
              <div
                className={"container-fluid"}
                style={{ width: "100%", marginBottom: "100px" }}
              >
                <Row>
                  {data.length > 0 ? (
                    data.map(function (val) {
                      return (
                        <Col xs={12} md={4} key={`${val.title_slug}`}>
                          <Link
                            to={`${locale}/article/${val.title_slug}`}
                            className="container-fluid mb-2"
                            style={{ textDecoration: "none" }}
                          >
                            <img
                              src={`${val.imageUrl}`}
                              className="card-img-top rounded shadow"
                              alt="..."
                              style={{ minHeight: 20 }}
                            />
                            <div className="card-body pt-0 mt-0">
                              <span style={{ color: "#AD1457" }}>
                                {moment(val.created_at).format("MMMM D, YYYY")}
                              </span>
                              <br />
                              <h3 className="card-title text-dark">
                                {val.title}
                              </h3>
                            </div>
                          </Link>
                        </Col>
                      )
                    })
                  ) : (
                    <div className="col-12 text-center text-black-50">
                      {intl.formatMessage({ id: "no-content" })}
                    </div>
                  )}
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    </div>
  )
}

export default Articles
